export const getClinicMainMenu = (isMarketplaceOnly: boolean, canReadUsers: boolean, hasGmailAccountLinked: boolean) => {
  const pages: Array<any> = [];
  if (!isMarketplaceOnly) {
    pages.push({
      heading: "Dashboard",
      route: "/dashboard",
      fontIcon: "bi-speedometer2",
    });
    if (hasGmailAccountLinked) {
      pages.push({
        heading: "Email",
        route: "/emails",
        fontIcon: "bi-inbox",
        notifications: "emails.unread",
        requiresGmailLinked: true,
      });
    }
    pages.push({
      heading: "Calendar",
      route: "/calendar",
      fontIcon: "bi-calendar4-week",
    });
    pages.push({
      heading: "Events",
      route: "/operations",
      fontIcon: "bi-journal-check",
      notifications: "operations.notDownPaid",
    });
    pages.push({
      heading: "Patients",
      route: "/patients",
      fontIcon: "bi-people",
      notifications: "patients.prospects",
    });
  };

  pages.push({
    heading: "Marketplace",
    route: "/marketplace",
    fontIcon: "bi-shop",
  });
  pages.push({
    heading: "Orders",
    route: "/orders",
    fontIcon: "bi-cart-check",
    notifications: "orders.pendingPayment",
  });
  pages.push({
    heading: "Invoices",
    route: "/invoices",
    fontIcon: "bi-file-earmark-post-fill",
  });

  if (!isMarketplaceOnly) {
    pages.push({
      heading: "Stocks",
      route: "/stocks",
      fontIcon: "bi-box",
    });
    pages.push({
      heading: "Email templates",
      route: "/email-templates",
      fontIcon: "bi-envelope",
    });
    pages.push({
      heading: "Support",
      route: "/tickets",
      fontIcon: "bi-question-circle",
      notifications: "support.pendingTickets",
    });
    if (canReadUsers) {
      pages.push({
        heading: "Users",
        route: "/users",
        fontIcon: "bi-people",
      });
    }
  }

  pages.push({
    heading: "Settings",
    route: "/settings",
    fontIcon: "bi-sliders",
  });

  return [ { pages } ];
};
