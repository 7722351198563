
import JwtService from "@/core/services/JwtService";
import { defineComponent } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  setup() {
    const store = useStore();

    return {
      store,
      toolbarWidthFluid,
    };
  },
  computed: {
    loggedUser() {
      const user = JwtService.getUser();
      return `${user.firstname} ${user.lastname}`;
    },
  },
  methods: {
    logout() {
      this.store.dispatch(Actions.LOGOUT);
      this.$router.push('sign-in');
    },
  },
});
