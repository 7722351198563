
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const showGuidedTour = !JwtService.isSuperAdmin() && !JwtService.isMarketplaceOnly();
    return {
      footerWidthFluid,
      showGuidedTour,
    };
  },
});
