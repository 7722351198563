
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { getClinicMainMenu } from "@/core/config/ClinicMainMenuConfig";
import NeorisAdminMainMenuConfig from "@/core/config/NeorisAdminMainMenuConfig";
import JwtService from "@/core/services/JwtService";
import _ from "lodash";

export default defineComponent({
  name: "kt-menu",
  components: {},
  data() {
    return {
      notifications: {},
      notificationsInterval: 0,
    }
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const canAccessUsers = Boolean(JwtService.getUserRole()?.rights?.find(e => e.section == "users")?.read);
    // const hasGmailAccountLinked = JwtService.hasGmailAccountLinked();
    const hasGmailAccountLinked = true;
    const MainMenuConfig = JwtService.isSuperAdmin()
      ? NeorisAdminMainMenuConfig
      : getClinicMainMenu(JwtService.isMarketplaceOnly(), canAccessUsers, hasGmailAccountLinked);

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },
  mounted() {
    this.refreshNotifications();
    this.notificationsInterval = setInterval(() => {
      this.refreshNotifications();
    }, 15000); // Refresh every 20 secs
  },
  beforeUnmount() {
    clearInterval(this.notificationsInterval);
  },
  computed: {
    loggedUserHasGmailLinked() {
      const user = JwtService.getUser();
      if (!user) return false;
      return !!user.clinic.gmailApiToken && user.clinic.gmailApiToken.access_token;
    },
  },
  methods: {
    refreshNotifications() {
      // ApiService.query('notifications', {}).then(({ data }) => {
      //   this.notifications = data;
      // });
    },
    getNotifications(path) {
      return 0;
      // return _.get(this.notifications, path);
    },
  },
});
