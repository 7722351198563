const DocMenuConfig = [
    {
      pages: [
        {
          heading: "Dashboard",
          route: "/dashboard",
          fontIcon: "bi-speedometer2",
        },
        {
          heading: "Prospects Audit Trail",
          route: "/prospects-acquisition",
          fontIcon: "bi-person-lines-fill",
        },
        {
          heading: "Prospects Redirection",
          route: "/prospects-redirection",
          fontIcon: "bi-arrow-left-right",
        },
        {
          heading: "Prospects",
          route: "/prospects",
          fontIcon: "bi-people",
        },
        {
          heading: "Clinics",
          route: "/clinics",
          fontIcon: "bi-building",
        },
        {
          heading: "Orders",
          route: "/orders",
          fontIcon: "bi-cart-check",
        },
        {
          heading: "Invoices",
          route: "/invoices",
          fontIcon: "bi-file-earmark-post-fill",
        },
        {
          heading: "Products",
          route: "/products",
          fontIcon: "bi-box",
        },
        {
          heading: "Email templates",
          route: "/email-templates",
          fontIcon: "bi-envelope",
        },
        {
          heading: "Contact Questions",
          route: "/contact-questions",
          fontIcon: "bi-question-diamond",
        },
        {
          heading: "Support Tickets",
          route: "/tickets",
          fontIcon: "bi-question-circle",
        },
        {
          heading: "Website Analytics",
          path: "https://analytics.google.com/analytics/web/",
          fontIcon: "bi-graph-up-arrow",
        },
        {
          heading: "Payments (Mollie)",
          path: "https://my.mollie.com/dashboard/login",
          fontIcon: "bi-currency-dollar",
        },
      ],
    },
  ];
  
  export default DocMenuConfig;
  