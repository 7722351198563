import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "alert alert-danger mx-4",
  role: "alert"
}
const _hoisted_4 = {
  key: 1,
  class: "alert alert-warning mx-4",
  role: "alert"
}
const _hoisted_5 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_6 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        (_ctx.mustSetupPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createTextVNode(" You must "),
              _createVNode(_component_router_link, { to: "/settings#payment" }, {
                default: _withCtx(() => [
                  _createTextVNode(" setup a valid payment method ")
                ]),
                _: 1
              }),
              _createTextVNode(" in order to receive new prospects in your dashboard! ")
            ]))
          : _createCommentVNode("", true),
        (_ctx.mustSetupPresentation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode(" Hi! You did not fill your clinic's presentation. Don't forget to update it in "),
              _createVNode(_component_router_link, { to: "/settings" }, {
                default: _withCtx(() => [
                  _createTextVNode("\"Settings\"")
                ]),
                _: 1
              }),
              _createTextVNode(" > \"Neoris website\" as it appears on our website. Thank you! ")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.subheaderDisplay)
            ? (_openBlock(), _createBlock(_component_KTToolbar, {
                key: 0,
                breadcrumbs: _ctx.breadcrumbs,
                title: _ctx.pageTitle
              }, null, 8, ["breadcrumbs", "title"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              id: "kt_content_container",
              class: _normalizeClass({
              'container-fluid': _ctx.contentWidthFluid,
              'container-xxl': !_ctx.contentWidthFluid,
            })
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp)
  ], 64))
}